var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "sender-id-table-action-dialog"
  }, [_vm._m(0), _c('div', {
    staticClass: "top-section"
  }, [_c('h5', [_vm._v("Are you sure you want to perform this action?")]), _c('p', [_vm._v(" You are about to perform this action on "), _c('span', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.multiSelectedData.length))]), _vm._v(" user(s). ")])]), _c('div', {
    staticClass: "action-container"
  }, [!['approved'].some(item => _vm.$route.name.includes(item)) ? _c('el-button', {
    attrs: {
      "type": "success",
      "loading": _vm.approveLoading
    },
    on: {
      "click": _vm.approveResellerBulk
    }
  }, [_vm._v("Approve")]) : _vm._e(), !['declined'].some(item => _vm.$route.name.includes(item)) ? _c('el-button', {
    attrs: {
      "type": "danger",
      "loading": _vm.declineLoading
    },
    on: {
      "click": _vm.declineResellerBulk
    }
  }, [_vm._v("Decline")]) : _vm._e(), !['pending user verification sender IDs'].some(item => _vm.$route.name.includes(item)) ? _c('el-button', {
    attrs: {
      "type": "warning",
      "loading": _vm.holdLoading
    },
    on: {
      "click": _vm.holdResellerBulk
    }
  }, [_vm._v("Hold")]) : _vm._e()], 1), _c('div', {
    staticClass: "cancel-selection"
  }, [_c('el-button', {
    attrs: {
      "type": "danger"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('update:visible', false);
      }
    }
  }, [_vm._v("Cancel")])], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('header', [_c('h5', {
    staticStyle: {
      "color": "#f7921c"
    }
  }, [_vm._v("Bulk Edit Sender IDs")])]);
}];
export { render, staticRenderFns };